<template>
  <form data-vv-scope="form-2">
    <div class="row">
      <div class="col-md-12">
        <div class="row payment-view">
          <b-overlay :show="showOverlay" no-wrap></b-overlay>
          <div class="col-md-6 mb-view-pr mb-view-pl">
            <div class="card-body card">
              <div class="row">
                <div class="col-md-12">
                  <h3>Total Amount</h3>
                </div>
                <div class="col-md-12">
                  <ul class="list-group mb-3 payment-details">
                    <li class="d-flex justify-content-between lh-sm border-bottom mb-3">
                      <div>
                        <h6 class="my-0"><strong>{{ pricing.membership_name }}</strong></h6>
                      </div>
                      <span class="text-muted"><strong>${{ pricing.formatted_price }}</strong></span>
                    </li>
                    <li class="d-flex justify-content-between">
                      <div class="coupon-inner mb-3">
                        <h6 class="my-0"><strong>Coupon</strong></h6>
                      </div>
                      <div>
                        <input type="text" class="form-control coupon-text" v-model="couponTxt">
                        <button @click.prevent="applyCoupon()" class="btn-apply coupon-btn">{{button.coupon}}</button>
                        <span class="text-sm text-success" v-if="couponSuccess">{{ couponSuccess }}</span>
                        <span class="text-sm text-danger" v-if="couponError">{{ couponError }}</span>
                      </div>
                    </li>
                    <li class="d-flex justify-content-between lh-sm" v-if="couponApplied">
                      <div>
                        <h6 class="my-0">
                          <strong>
                            <a href="javascript:void(0)" class="mr-2 text-danger" @click="removeCoupon">
                              <i class="fa fa-trash"></i>
                            </a>
                            Discount - {{ this.couponApplyTxt }}
                          </strong>
                        </h6>
                      </div>
                      <span class="text-muted"><strong>-${{ totals.discount | formatTotal }}</strong></span>
                    </li>
                    <li class="d-flex justify-content-between lh-sm border-top mt-2 pt-3 border-bottom mb-3">
                      <div>
                        <h6 class="my-0"><strong>Sub Total</strong></h6>
                      </div>
                      <span class="text-muted"><strong>${{ totals.sub_total | formatTotal }}</strong></span>
                    </li>
                    <template v-if="processingFeePercent">
                      <li class=" d-flex justify-content-between lh-sm">
                        <div class="processing-tooltip">
                          <h6 class="my-0"><strong>Technology/Processing Fee
                            ({{ this.processingFeePercent }}%)
                            <b-button v-b-tooltip.hover
                                      title="This small fee enables USA-WSWS to make technology upgrades to enhance your membership experience.">
                              <img v-bind:src="'/dist/img/memberregistration/tooltip-icon-small.png'"
                                   alt="tooltipicon"/></b-button>
                          </strong></h6>
                        </div>
                        <span class="text-danger"><strong>${{ totals.processing_fee | formatTotal }}</strong></span>
                      </li>
                    </template>
                    <li class=" d-flex justify-content-between lh-sm border-top mt-2 pt-3">
                      <div>
                        <h6 class="my-0"><strong>Grand Total</strong></h6>
                      </div>
                      <span class="text-danger"><strong>${{ totals.grand_total | formatTotal }}</strong></span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="card-body card">
              <div class="row">
                <div class="col-md-6">
                  <h3>Member Details</h3>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6 col-sm-6">
                  <label>First Name</label>
                  <span>{{ formData.first_name }}</span>
                </div>
                <div class="col-md-6 col-sm-6">
                  <label>Last Name</label>
                  <span>{{ formData.last_name }}</span>
                </div>
                <div class="col-md-6 col-sm-6">
                  <label>Email</label>
                  <span>{{ formData.email }}</span>
                </div>
                <div class="col-md-6 col-sm-6">
                  <label>Phone Number</label>
                  <span>{{ formData.phone_1 }}</span>
                </div>
                <div class="col-md-6 col-sm-6">
                  <label>Website</label>
                  <span>{{ formData.website }}</span>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-6 mb-view-pl mb-view-pr">
            <div class="card-body card card-wrapper" v-if="totals.grand_total">
                <div class="row">
                  <template>
                    <div class="col-md-12 card-info">
                      <h3>Card Information</h3>
                    </div>
                    <div class="col-md-12">
                      <div class="form-group">
                        <label class="form-label">Card Number<sup class="text-danger">*</sup></label>
                        <input type="text" :class="{ 'is-invalid': formErrors.has('form-2.cardNumber') }"
                               maxlength="16" placeholder="#### #### #### ####" class="form-control"
                               name="cardNumber" v-validate="'required|credit_card'" data-vv-as="Card Number"
                               v-model="paymentForm.card_number"
                               @keypress="isNumber($event)">
                        <div v-show="formErrors.has('form-2.cardNumber')" class="invalid-feedback">
                          {{ formErrors.first('form-2.cardNumber') }}
                        </div>
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="form-group">
                        <label class="form-label">Expiration Month<sup class="text-danger">*</sup></label>
                        <select class="form-control form-select"
                                :class="{ 'is-invalid': formErrors.has('form-2.expiration_month') }"
                                v-validate="'required'" data-vv-as="Month" id="expiration-month" required=""
                                name="expiration_month" v-model="paymentForm.expiration_month">
                          <option value="">Month</option>
                          <option>01</option>
                          <option>02</option>
                          <option>03</option>
                          <option>04</option>
                          <option>05</option>
                          <option>06</option>
                          <option>07</option>
                          <option>08</option>
                          <option>09</option>
                          <option>10</option>
                          <option>11</option>
                          <option>12</option>
                        </select>
                        <div v-show="formErrors.has('form-2.expiration_month')" class="invalid-feedback">
                          {{ formErrors.first('form-2.expiration_month') }}
                        </div>
                      </div>
                    </div>
                    <div class="col-md-4 mp-l-r pl-0 pr-0">
                      <div class="form-group">
                        <label class="form-label">Expiration Year</label>
                        <select class="form-select form-control" id="expiration-year"
                                :class="{ 'is-invalid': formErrors.has('form-2.expiration_year') }"
                                v-validate="'required'" data-vv-as="Year"
                                name="expiration_year" v-model="paymentForm.expiration_year">
                          <option value="">Year</option>
                          <option v-for="year in yearArr" :value="year" :key="year">{{ year }}</option>
                        </select>
                        <div v-show="formErrors.has('form-2.expiration_year')" class="invalid-feedback">
                          {{ formErrors.first('form-2.expiration_year') }}
                        </div>
                      </div>
                    </div>
                    <div class="col-md-4 classcvv">
                      <div class="form-group">
                        <label class="form-label">CVV/CVC<sup class="text-danger">*</sup></label>
                        <input type="password" class="form-control"
                               :class="{ 'is-invalid': formErrors.has('form-2.cvv') }" v-validate="{ required: true, regex: /^([0-9]+)$/ }"
                               name="cvv" maxlength="4" data-vv-as="CVV/CVC"
                               v-model="paymentForm.cvv" @keypress="isNumber($event)">
                        <div v-show="formErrors.has('form-2.cvv')" class="invalid-feedback">
                          {{ formErrors.first('form-2.cvv') }}
                        </div>
                      </div>
                    </div>
                    <div class="col-md-12">
                      <div class="form-group">
                        <label class="form-label">Name on Card<sup class="text-danger">*</sup></label>
                        <input type="text"
                               :class="{ 'is-invalid': formErrors.has('form-2.billing_firstname') }"
                               v-validate="'required'" placeholder="William" class="form-control"
                               id="billing_firstname" data-vv-as="Name on Card"
                               name="billing_firstname" v-model="paymentForm.name_on_card">
                        <div v-show="formErrors.has('form-2.billing_firstname')" class="invalid-feedback">
                          {{ formErrors.first('form-2.billing_firstname') }}
                        </div>
                      </div>
                    </div>
                  </template>
                </div>
            </div>
            <div class="card-body card card-wrapper" v-if="totals.grand_total">
              <div class="row">
                <div class="col-md-12">
                  <h3>Billing Address</h3>
                </div>
                <div class="col-md-12">
                  <div class="form-group">
                    <label>Mailing Address<sup class="text-danger">*</sup></label>
                    <input type="text" class="form-control" maxlength="45"
                           :class="{ 'is-invalid': formErrors.has('form-2.billing_addressline1') }"
                           v-validate="'required'" data-vv-as="Mailing Address"
                           name="billing_addressline1"
                           v-model="paymentForm.billing_addressline1">
                    <div v-show="formErrors.has('form-2.billing_addressline1')" class="invalid-feedback">
                      {{ formErrors.first('form-2.billing_addressline1') }}
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label>City<sup class="text-danger">*</sup></label>
                    <input type="text" class="form-control" maxlength="45" name="billing_city"
                           :class="{ 'is-invalid': formErrors.has('form-2.billing_city') }"
                           v-validate="'required'" data-vv-as="City"
                           v-model="paymentForm.billing_city">
                    <div v-show="formErrors.has('form-2.billing_city')" class="invalid-feedback">
                      {{ formErrors.first('form-2.billing_city') }}
                    </div>
                  </div>
                </div>
                <div class="col-md-6 pr-0" v-if="paymentForm.billing_country === 'USA'">
                  <div class="form-group">
                    <label>State<sup class="text-danger">*</sup></label>
                    <select class="form-control" id="state_code" name="billing_state_code"
                            :class="{ 'is-invalid': formErrors.has('form-2.billing_state_code') }"
                            v-validate="'required'" data-vv-as="State"
                            v-model="paymentForm.billing_state_code">
                      <option v-for="listOfState in listOfStates" :key="listOfState.state_code"
                              :value="listOfState.state_code"
                              :selected="paymentForm.billing_state_code == listOfState.state_code">
                        {{ listOfState.state }}
                      </option>
                    </select>
                    <div v-show="formErrors.has('form-2.billing_state_code')" class="invalid-feedback">
                      {{ formErrors.first('form-2.billing_state_code') }}
                    </div>
                  </div>
                </div>
                <div class="col-md-6" v-else>
                  <div class="form-group">
                    <label for="state_code" class="form-label">Other State <sup
                        class="text-danger">*</sup></label>
                    <input type="text" class="form-control" maxlength="45"
                           :class="{ 'is-invalid': formErrors.has('form-2.billing_state') }"
                           v-validate="'required'" data-vv-as="Other State"
                           v-model="paymentForm.billing_state" name="billing_state">
                    <div v-show="formErrors.has('form-2.billing_state')" class="invalid-feedback">
                      {{ formErrors.first('form-2.billing_state') }}
                    </div>
                  </div>
                </div>
                <div class="col-md-6 pr-0">
                  <div class="form-group">
                    <label>Zip / Postal Code<sup class="text-danger">*</sup></label>
                    <input type="text" class="form-control" maxlength="15" name="billing_zip"
                           :class="{ 'is-invalid': formErrors.has('form-2.billing_zip') }"
                           v-validate="'required'" data-vv-as="Zip / Postal Code"
                           v-model="paymentForm.billing_zip">
                    <div v-show="formErrors.has('form-2.billing_zip')" class="invalid-feedback">
                      {{ formErrors.first('form-2.billing_zip') }}
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label>Country<sup class="text-danger">*</sup></label>
                    <select class="form-control" id="country" v-model="paymentForm.billing_country"
                            :class="{ 'is-invalid': formErrors.has('form-2.billing_country') }"
                            v-validate="'required'" data-vv-as="Country"
                            name="billing_country">
                      <option v-for="listOfCountry in listOfCountries" :key="listOfCountry.country"
                              :value="listOfCountry.country">
                        {{ listOfCountry.country }}
                      </option>
                    </select>
                    <div v-show="formErrors.has('form-2.billing_country')" class="invalid-feedback">
                      {{ formErrors.first('form-2.billing_country') }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="card-body card card-wrapper">
              <div class="row">
                <div class="col-md-12 payment-conf confirmsc">
                  <h3>Confirm</h3>
                  <p v-if="prop_confirmation.label" v-html="prop_confirmation.label"></p>
                  <div class="form-check">
                    <input type="checkbox" id="payment_agree"
                           class="form-check-input" v-model="paymentForm.agree"
                           :class="{ 'is-invalid': formErrors.has('form-2.agree') }"
                           v-validate="'required'"
                           name="agree">
                    <label for="payment_agree" class="form-check-label">
                      <input type="text"
                             class="chkboxinput"
                             v-model="paymentForm.sign"
                             :class="{ 'is-invalid': formErrors.has('form-2.sign') }"
                             v-validate="'required'"
                             name="sign"
                             maxlength="2"
                             @keypress="isAlphabets($event)">
                      <span :style="{ 'display': 'inline-block' }" v-if="prop_confirmation.agree"
                            v-html="prop_confirmation.agree"></span>
                    </label>
                    <span v-if="prop_confirmation.note" v-html="prop_confirmation.note"></span>
                    <div v-show="formErrors.has('form-2.agree')" class="invalid-feedback">
                      {{ formErrors.first('form-2.agree') ? 'Please acknowledge by checking the box' : '' }}
                    </div>
                    <div v-show="formErrors.has('form-2.sign')" class="invalid-feedback">
                      {{ formErrors.first('form-2.sign') ? 'Please enter your initials to proceed' : '' }}
                    </div>
                    <p v-if="paymentError" class="text-danger">
                      {{ paymentError }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12 text-right">
            <button type="button" class="btn btn-secondary btnnextstp mr-2" @click="previousStep">
              <i class="fa fa-arrow-alt-circle-left fa-lg mr-2"></i>Back
            </button>
            <button type="button" class="btn btn-secondary btnnextstp btn-next-success" @click="payNow">
              <i class="fa fa-lg mr-2" :class="showOverlay ? 'fa-circle-notch fa-spin' : 'fa-check'"></i>Pay Now
            </button>
          </div>
        </div>
      </div>
    </div>
  </form>
</template>
<script>
import axios from 'axios';

export default {
  props: ['formData', 'pricing'],
  data(){
    return {
      showOverlay: false,
      button: {
        coupon: "Apply Coupon"
      },
      processingFeePercent: 3,
      couponApplied: false,
      couponTxt: null,
      couponSuccess: null,
      couponError: null,
      coupon_id: null,
      couponApplyTxt: null,
      totals: {
        sub_total: 0,
        discount: 0,
        processing_fee: 0,
        grand_total: 0
      },
      paymentForm: {
        card_number: null,
        expiration_month: "",
        expiration_year: "",
        cvv: null,
        name_on_card: null
      },
      paymentError: null
    };
  },
  methods: {
    isNumber: function(evt) {
      if (evt != '') {
        evt = (evt) ? evt : window.event;
        var charCode = (evt.which) ? evt.which : evt.keyCode;
        if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
          evt.preventDefault();
        } else {
          return true;
        }
      }
    },
    previousStep(){
      this.$emit('wizardPrevStep');
    },
    calculateTotals(){
      this.totals.sub_total = this.pricing.price - this.totals.discount;
      this.totals.processing_fee = this.totals.sub_total * (this.processingFeePercent/100);
      this.totals.grand_total = this.totals.sub_total + this.totals.processing_fee;
    },
    applyCoupon(){
      if (this.couponTxt) {
        this.couponApplied = false;
        this.couponApplyTxt = null;
        this.couponSuccess = null;
        this.couponError = null;
        this.coupon_id = null;
        this.totals.discount = 0;
        this.calculateTotals();
        let data = { coupon_code: this.couponTxt, price: this.totals.sub_total, coupon_type_id: 2 };
        axios.post(this.basePath+"api/campski/renew/apply-coupon", data, {headers: this.memberHeaders})
        .then(response => {
          if(response.data.status === "error") {
            this.couponError = response.data.message;
          } else {
            this.couponApplied = true;
            this.totals.discount = response.data.data.value;
            this.coupon_id = response.data.data.id;
            this.couponSuccess = response.data.message;
            this.couponApplyTxt =  response.data.data.text + ' off membership (' + this.couponTxt + ')';
            this.calculateTotals();
          }
        }).catch(err => {
          console.log(err);
        });
      }
    },
    removeCoupon(){
      this.couponApplied = false;
      this.couponSuccess = null;
      this.couponError = null;
      this.totals.discount = 0;
      this.couponTxt = null;
      this.couponApplyTxt = null;
      this.coupon_id = null;
      this.calculateTotals();
    },
    fillBillingAddress(){
      this.paymentForm.billing_addressline1 = this.formData?.primaryContact?.address1;
      this.paymentForm.billing_city = this.formData?.primaryContact?.city;
      this.paymentForm.billing_state_code = this.formData?.primaryContact?.state_code;
      this.paymentForm.billing_state = this.formData?.primaryContact?.state;
      this.paymentForm.billing_zip = this.formData?.primaryContact?.zip;
      this.paymentForm.billing_country = "USA";
    },
    payNow(){
      this.$validator.validateAll('form-2').then((result) => {
        if (result) {
          this.paymentApi();
        } else {
          document.getElementById('app').scrollIntoView();
        }
      });
    },
    paymentApi(){
      this.showOverlay = true;
      this.paymentForm.card_number = this.paymentForm?.card_number?.replace(/\s/g, '');
      let data = {
        pricing: this.pricing,
        memberInfo: this.formData,
        paymentForm: this.paymentForm,
        totals: this.totals,
        coupon_id: this.coupon_id
      };
      axios.post(this.basePath + 'api/campski/renew/paynow', data, { headers: this.memberHeaders })
      .then((res) => {
        if (res.data.status === 'success') {
          this.showOverlay = false;
          this.$emit("paymentSuccess", res.data.data);
          document.getElementById('app').scrollIntoView();
        } else {
          this.showLoader = false;
          this.paymentError = res.data.message;
        }
      })
      .catch((error) => {
        this.showLoader = false;
      });
    }
  },
  mounted() {
    this.calculateTotals();
    const year = new Date().getFullYear();
    this.yearArr = Array.from({length: 12}, (value, index) => year + index);
    this.fillBillingAddress();
  },
  filters: {
    formatTotal(value){
      return parseFloat(value).toFixed(2)
    }
  },
  computed: {
    prop_confirmation: function(){
      return this.getSiteProps('memberRegistration.campskiConfirmation') ?? {};
    }
  }
}
</script>