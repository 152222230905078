<template>
  <div class="content-header">
    <div class="container-fluid">
      <div class="row mb-2">
        <div class="col-sm-6">
          <h1 class="m-0 text-dark">Renew Membership</h1>
        </div><!-- /.col -->
        <div class="col-sm-6">
          <ol class="breadcrumb float-sm-right">
            <li class="breadcrumb-item"><a href="#">Home</a></li>
            <li class="breadcrumb-item">Renew Membership</li>
          </ol>
        </div><!-- /.col -->
      </div><!-- /.row -->
    </div><!-- /.container-fluid -->
    <div class="row rm-p-m" v-if="loading" style="position: absolute; left:15%; right:0; top:50%;">
      <div class="col-md-12 text-center p-3">
        <i class="fa fa-circle-notch fa-spin fa-2x"></i>
      </div>
    </div>
    <div class="row rm-p-m" v-else>
      <div class="col-md-12">
        <div class="clubrenewal">
          <div class="tabbable">
            <div class="h-steps ">
              <section>
                <ul class="header-navigation">
                  <li><span :class="wiz_step === 1 ? 'header-steps current-item' : 'header-steps'">Verify</span></li>
                  <li><span :class="wiz_step === 2 ? 'header-steps current-item' : 'header-steps'">Payment</span></li>
                  <li><span :class="wiz_step === 3 ? 'header-steps current-item' : 'header-steps'">Confirmation</span>
                  </li>
                </ul>
              </section>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-12">
        <b-overlay :show="overlayLoader" no-wrap></b-overlay>
        <Verify v-if="wiz_step === 1" :formData="memberInfo" @recordVerify="recordVerify" />
        <Payment v-if="wiz_step === 2" :formData="memberInfo" :pricing="pricing" @wizardPrevStep="wizardPrevStep" @paymentSuccess="paymentSuccess" />
        <Confirmation v-if="wiz_step === 3" :confirmationData="confirmationData" />
      </div>
      <div class="col-md-12 text-right mt-2" v-if="wiz_step < 3">
        <b-overlay :show="overlayLoader" no-wrap></b-overlay>
        <p class="text-danger" v-if="wiz_step === 2 && this.validateVerifyStep === false">
          Please fill the mandatory fields to proceed further
        </p>
      </div>
    </div>
  </div>
</template>
<script>
import { EventBus } from '@/event-bus';
import axios from 'axios';
import Verify from './Verify';
import Payment from './Payment';
import Confirmation from "./Confirmation";
export default {
  name: "campski-renewal",
  components: {Verify, Payment, Confirmation},
  data(){
    return {
      memberInfo: {},
      pricing: {},
      loading: false,
      overlayLoader: false,
      validateVerifyStep: true,
      wiz_step: 1,
      confirmationData: null
    };
  },
  methods: {
    recordVerify(obj){
      this.memberInfo = obj.memberInfo;
      this.pricing = obj.pricing;
      this.wiz_step += 1;
      document.getElementById('app').scrollIntoView();
    },
    wizardPrevStep(){
      this.wiz_step -= 1;
      document.getElementById('app').scrollIntoView();
    },
    paymentSuccess(obj){
      this.confirmationData = obj;
      this.wiz_step += 1;
    }
  }
}
</script>