<template>
  <div class="row" v-if="tabLoading">
    <div class="col-md-12 my-4 text-center"><i class="fa fa-circle-notch fa-spin fa-2x"></i></div>
  </div>
  <div class="row" v-else-if="pricing.price === 0">
    <div class="col-md-12 text-center my-1 card card-body"><h1>Oops! renewal is disabled. Please come back later!</h1></div>
  </div>
  <div class="profile-body user-details mt-1" :key="updateIndex" v-else>
    <div class="row">
      <div class="col-md-6 mb-3">
        <div class="card">
          <div class="card-header">
            <div class="header-title">
              <h3 v-if="membershipName">{{ membershipName }} Information</h3>
              <h3 v-else>Camp/Ski School Information</h3>
            </div>
            <div class="modal-btn">
              <b-button @click="editSchoolInfo"><span class="fa fa-edit mr-2"></span>Edit</b-button>
            </div>
          </div>
          <div class="card-body">
            <div class="row">
              <div  class="col-md-6 col-lg-4">
                <div class="form-group">
                  <label class="form-label" :class="{'text-danger font-weight-bold': viewDataError.company}">Camp/School Name</label>
                  <span>{{ memberInfo.company || "N/A" }}</span>
                </div>
              </div>
              <div  class="col-md-6 col-lg-4 ">
                <div class="form-group">
                  <label class="form-label" :class="{'text-danger font-weight-bold': viewDataError.first_name && viewDataError.last_name }">Contact Name (Director)</label>
                  <span>{{ memberInfo.first_name + " " + memberInfo.last_name }}</span>
                </div>
              </div>
              <div  class="col-md-6 col-lg-4">
                <div class="form-group">
                  <label class="form-label">Membership #</label>
                  <span>{{profileData.confirmation_code || "N/A"}}</span>
                </div>
              </div>
              <div  class="col-md-6 col-lg-4">
                <div class="form-group">
                  <div class="d-flex">
                    <label class="form-label">Membership Status  <span v-if="profileData.membership_status_info" v-b-tooltip.hover :title="profileData.membership_status_info">
                                             <img  style="width:18px;" v-if="getSiteProps('profile.membershipStatusIcon')" v-bind:src="'/dist/img/memberregistration/tooltip-icon-small.png'" alt="tooltipicon" />
                                            </span></label>
                    <span class="text-danger bordernote" v-if="profileData.note" v-b-tooltip.hover :title="profileData.note"><i class="fas fa-clipboard"></i></span>
                  </div>

                  <span class="text-success" v-if="profileData.status_text == 'Current'"><img class="mr-1" src="/dist/img/profile/ico-status-current.png"> {{ profileData.status_text }} (Expires {{profileData.valid_thru | formatDob }})</span>
                  <span class="text-warning" v-else-if="profileData.status_text != 'Expired'"><img class="mr-1" src="/dist/img/profile/ico-status-pending.png"> {{ profileData.status_text || 'N/A' }}</span>
                  <span class="text-warning" v-else> <img class="mr-1" src="/dist/img/profile/ico-status-pending.png"> Expired</span>
                </div>
              </div>
              <div  class="col-md-6 col-lg-4">
                <div class="form-group">
                  <label class="form-label">Start Date</label>
                  <span>{{ membershipStartDate }}</span>
                </div>
              </div>
              <div  class="col-md-6 col-lg-4">
                <div class="form-group">
                  <label class="form-label">Expiration Date</label>
                  <span>{{ profileData && profileData.membership && profileData.membership.period != 999 ? membershipEndDate : "N/A"}}</span>
                </div>
              </div>
              <div  class="col-md-6 col-lg-4">
                <div class="form-group">
                  <label class="form-label" :class="{'text-danger font-weight-bold': viewDataError.website}">Website</label>
                  <span>{{ memberInfo.website|| "N/A" }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="card">
          <div class="card-header">
            <div class="header-title">
              <h3>Additional Information</h3>
            </div>
            <div  class="modal-btn">
              <b-button @click="editAdditionalInfo"><span class="fa fa-edit mr-2"></span>Edit</b-button>
            </div>
          </div>
          <div class="card-body">
            <div  class="row">
              <div  class="col-md-6 col-lg-4">
                <div class="form-group">
                  <label class="form-label">Closest Airport</label>
                  <span>{{ memberInfo.closest_airport || "N/A" }}</span>
                </div>
              </div>
              <div  class="col-md-6 col-lg-4">
                <div class="form-group">
                  <label class="form-label">Number of Instructors</label>
                  <span>{{ memberInfo.number_of_instructor }}</span>
                </div>
              </div>
              <div  class="col-md-6 col-lg-4">
                <div class="form-group">
                  <label class="form-label">Type of Camp/School</label>
                  <span>{{ memberInfo.type || "N/A" }}</span>
                </div>
              </div>
              <div  class="col-md-6 col-lg-4 ">
                <div class="form-group">
                  <label class="form-label" :class="{'text-danger font-weight-bold': viewDataError.month_of_operation}">Months of Operation</label>
                  <span>{{ memberInfo.month_of_operation }}</span>
                </div>
              </div>
              <div  class="col-md-6 col-lg-4">
                <div class="form-group">
                  <label class="form-label" :class="{'text-danger font-weight-bold': viewDataError.discipline_taught}">Disciplines Taught</label>
                  <span><ul><li v-for="(discipline) in disciplineTaught" v-bind:key="'discipline'+discipline">{{ discipline || "N/A"}}</li></ul></span>
                </div>
              </div>
              <div  class="col-md-6 col-lg-4">
                <div class="form-group">
                  <label class="form-label" :class="{'text-danger font-weight-bold': viewDataError.equipment}">Equipment</label>
                  <span><ul><li v-for="(equipment) in equipmentList" v-bind:key="'equipment'+equipment">{{ equipment || "N/A" }}</li></ul></span>
                </div>
              </div>
              <div class="col-md-6 col-lg-4">
                <div class="form-group">
                  <label class="form-label" :class="{'text-danger font-weight-bold': viewDataError.skill_level_taught}">Skill Levels Taught</label>
                  <span>{{ memberInfo.skill_level_taught || "N/A" }}</span>
                </div>
              </div>
              <div class="col-md-6 col-lg-4">
                <div class="form-group">
                  <label class="form-label" :class="{'text-danger font-weight-bold': viewDataError.boats}">Boats</label>
                  <span>{{ memberInfo.boats || "N/A" }}</span>
                </div>
              </div>
              <div class="col-md-6 col-lg-4">
                <div class="form-group">
                  <label class="form-label" :class="{'text-danger font-weight-bold': viewDataError.waterway_type}">Waterway Type</label>
                  <span>{{ waterWayList }}</span>
                </div>
              </div>
              <div class="col-md-6 col-lg-4">
                <div class="form-group">
                  <label class="form-label" :class="{'text-danger font-weight-bold': viewDataError.water_front}">Waterfront Site Description</label>
                  <span>{{ waterFrontList }}</span>
                </div>
              </div>
              <div class="col-md-6 col-lg-8">
                <div class="form-group">
                  <label class="form-label">Additional Information</label>
                  <span>{{ memberInfo.additional_information }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="card">
          <div class="card-header">
            <div class="header-title">
              <h3>Contact Information</h3>
            </div>
            <div  class="modal-btn" v-if="profileData.personal && (profileData.personal.relation===0 || profileData.personal.relation===1)">
              <b-button @click="editContactInfo"><span class="fa fa-edit mr-2"></span>Edit</b-button>
            </div>
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col-md-12">
                <div class="row">
                  <div  class="col-md-6">
                    <div class="form-group">
                      <label class="form-label" :class="{'text-danger font-weight-bold': viewDataError.email}">Email</label>
                      <span><a class="email-link" v-bind:href="'mailto:' + memberInfo.email ">{{ memberInfo.email || "N/A" }}</a></span>
                    </div>
                  </div>
                  <div  class="col-md-6">
                    <div class="form-group">
                      <label class="form-label" :class="{'text-danger font-weight-bold': viewDataError.phone_1}">Phone Number</label>
                      <span>{{ memberInfo.phone_1 || "N/A" }}</span>
                    </div>
                  </div>
                </div>
                <hr>
                <div class="row">
                  <div class="col-md-6">
                    <div class="form-group">
                      <label class="form-label" :class="{'text-danger font-weight-bold': viewDataError.primary_address1}">Camp/School Site Address (Line 1)</label>
                        <span>{{ primaryContact.address1 || "" }}</span>
                    </div>
                  </div>
                  <div  class="col-md-6">
                    <div class="form-group">
                      <label class="form-label">Address (Line 2)</label>
                      <span>{{ primaryContact.address2 || "" }}</span>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <label class="form-label" :class="{'text-danger font-weight-bold': viewDataError.primary_city}">City</label>
                      <span>{{ primaryContact.city || "" }}</span>
                    </div>
                  </div>
                  <div  class="col-md-6">
                    <div class="form-group">
                      <label class="form-label" :class="{'text-danger font-weight-bold': viewDataError.primary_state_code}">State</label>
                      <span>{{ primaryContact.state_code }}</span>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <label class="form-label" :class="{'text-danger font-weight-bold': viewDataError.primary_zip}">Zip Code</label>
                      <span>{{ primaryContact.zip || "" }}</span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-12">
                <hr>
                <div class="row">
                  <div  class="col-md-6">
                    <div class="form-group">
                      <label class="form-label" :class="{'text-danger font-weight-bold': viewDataError.secondary_address1}">Mailing Address (if different)</label>
                      <span>{{ secondaryContact.address1 || "" }}</span>
                    </div>
                  </div>
                  <div  class="col-md-6">
                    <div class="form-group">
                      <label class="form-label">Address (Line 2)</label>
                      <span>{{ secondaryContact.address2 || "" }}</span>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <label class="form-label" :class="{'text-danger font-weight-bold': viewDataError.secondary_city}">City</label>
                      <span>{{ secondaryContact.city || "" }}</span>
                    </div>
                  </div>
                  <div  class="col-md-6">
                    <div class="form-group">
                      <label class="form-label" :class="{'text-danger font-weight-bold': viewDataError.secondary_state_code}">State</label>
                      <span>{{ secondaryContact.state_code }}</span>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <label class="form-label" :class="{'text-danger font-weight-bold': viewDataError.secondary_zip}">Zip Code</label>
                      <span>{{ secondaryContact.zip || "" }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12 text-right">
        <p class="text-danger" v-if="viewDataErrorExists">Please provide values for all mandatory fields</p>
        <div class="btn-group">
          <button type="button" class="btn btn-secondary btnnextstp btn-next-success" @click="nextStep" :disabled="viewDataErrorExists">
            <i class="fa fa-arrow-alt-circle-right fa-lg mr-2"></i>Next
          </button>
        </div>
      </div>
    </div>
    <b-modal id="editSchoolInfo" size="lg" ok-title="Update" centered :title="membershipName+' Information'" @ok="updateSchoolInfo">
      <div class="form-body">
        <form data-vv-scope="form-1">
          <div class="row">
            <div class="col-md-12">
              <div class="form-group">
                <label class="form-label">Contact Name (Director)<sup class="text-danger">*</sup></label>
              </div>
              <div class="row">
                <div class="col-md-6">
                  <div class="form-group">
                    <input type="text" placeholder="First *" data-vv-as="first name" v-validate="'required'"
                           :class="{ 'is-invalid': formErrors.has('form-1.first_name') }" class="form-control"
                           v-model="form.first_name"
                           name="first_name">
                    <div v-show="formErrors.has('form-1.first_name')" class="invalid-feedback">
                      {{ formErrors.first('form-1.first_name') }}
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <input type="text" placeholder="Last *" data-vv-as="last name" v-validate="'required'"
                           :class="{ 'is-invalid': formErrors.has('form-1.last_name') }" class="form-control"
                           v-model="form.last_name"
                           name="last_name">
                    <div v-show="formErrors.has('form-1.last_name')" class="invalid-feedback">
                      {{ formErrors.first('form-1.last_name') }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label class="form-label">Camp/School Name<sup class="text-danger">*</sup></label>
                <input type="text" v-model="form.company" data-vv-as="camp/school name" v-validate="'required'"
                       :class="{ 'is-invalid': formErrors.has('form-1.company') }" class="form-control"
                       id="company" name="company"/>
                <div v-show="formErrors.has('form-1.company')" class="invalid-feedback">
                  {{ formErrors.first('form-1.company') }}
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label class="form-label">Website<sup class="text-danger">*</sup></label>
                <input type="text" class="form-control" v-validate="'required|url'"
                       :class="{ 'is-invalid': formErrors.has('form-1.website') }" v-model="form.website"
                       name="website">
                <div v-show="formErrors.has('form-1.website')" class="invalid-feedback">
                  {{ formErrors.first("form-1.website") }}
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </b-modal>
    <b-modal id="editAdditionalInfo" size="lg" ok-title="Update" centered title="Additional Information" @ok="updateAdditionalInfo">
      <div class="form-body">
        <form data-vv-scope="form-2">
          <div class="row">
            <div class="col-md-6">
              <div class="form-group">
                <label class="form-label">Closest Airport</label>
                <input type="text" class="form-control" v-model="form.closest_airport" name="closest_airport">
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group tooltipcss">
                <label class="form-label">Type of Camp/School</label>
                <b-button class="ml-2" v-b-tooltip.hover title="Please describe the type of camp or school (e.g. boys, girls, overnight, etc.).">
                  <img v-bind:src="'/dist/img/memberregistration/tooltip-icon-small.png'" alt="tooltipicon"/>
                </b-button>
                <input type="text" class="form-control" v-model="form.type" name="type">
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label class="form-label">Number of Instructors</label>
                <input type="number" class="form-control" v-validate="{ regex: /^([0-9]+)$/ }" v-model="form.number_of_instructor" name="number_of_instructor">
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label class="form-label">Months of Operation <sup class="text-danger">*</sup></label>
                <input type="text" data-vv-as="months of operation" class="form-control"
                       v-validate="'required'"
                       :class="{ 'is-invalid': formErrors.has('form-2.month_of_operation') }"
                       v-model="form.month_of_operation"
                       name="month_of_operation">
                <div v-show="formErrors.has('form-2.month_of_operation')" class="invalid-feedback">
                  {{ formErrors.first("form-2.month_of_operation") }}
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label class="form-label">Disciplines Taught<sup class="text-danger">*</sup></label>
              </div>
              <div class="form-group form-check" :class="{ 'is-invalid': formErrors.has('form-2.discipline_taught') }">
                <div v-for="(dt, index) in disciplinesTaught" :key="index">
                  <input v-validate="'required'" name="discipline_taught" data-vv-as="discipline taught" type="checkbox" :id="'dtaught'+index" v-model="form.discipline_taught" :value="dt.value">
                  <label :for="'dtaught'+index"> {{ dt.text }}</label>
                </div>
              </div>
              <div v-show="formErrors.has('form-2.discipline_taught')" class="invalid-feedback">
                {{ formErrors.first("form-2.discipline_taught") }}
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label class="form-label">Equipment<sup class="text-danger">*</sup></label>
                <div class="form-group form-check" :class="{ 'is-invalid': formErrors.has('form-2.equipment') }">
                  <div v-for="(eq, index) in equipment" :key="index">
                    <input v-validate="'required'" name="equipment" type="checkbox" :id="'equipment'+index" v-model="form.equipment" :value="eq.value">
                    <label :for="'equipment'+index"> {{ eq.text }}</label>
                  </div>
                </div>
                <div v-show="formErrors.has('form-2.equipment')" class="invalid-feedback">
                  {{ formErrors.first("form-2.equipment") }}
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label class="form-label">Skill Levels Taught<sup class="text-danger">*</sup></label>
                <div class="form-group form-check" :class="{ 'is-invalid': formErrors.has('form-2.skill_level_taught') }">
                  <div v-for="(st, index) in skillTaught" :key="index">
                    <input v-validate="'required'" name="skill_level_taught" data-vv-as="skill levels taught" type="checkbox" :id="'skilllevel'+index" v-model="form.skill_level_taught" :value="st.value">
                    <label :for="'skilllevel'+index"> {{ st.text }}</label>
                  </div>
                </div>
                <div v-show="formErrors.has('form-2.skill_level_taught')" class="invalid-feedback">
                  {{ formErrors.first("form-2.skill_level_taught") }}
                </div>

              </div>
            </div>
            <div class="col-md-6">
              <label class="form-label">Boats<sup class="text-danger">*</sup></label>
              <div class="form-group form-check" :class="{ 'is-invalid': formErrors.has('form-2.boats') }">
                <div v-for="(bt, index) in boats" :key="index">
                  <input v-validate="'required'" name="boats"  type="checkbox" :id="'boats'+index" v-model="form.boats" :value="bt.value">
                  <label :for="'boats'+index"> {{ bt.text }}</label>
                </div>
              </div>
              <div v-show="formErrors.has('form-2.boats')" class="invalid-feedback">
                {{ formErrors.first("form-2.boats") }}
              </div>
            </div>
            <div class="col-md-6">
              <div>
                <label class="form-label">Waterway Type <sup class="text-danger">*</sup></label>
              </div>
              <div class="form-group">
                <select class="form-control" data-vv-as="waterway Type" v-validate="'required'"
                        :class="{ 'is-invalid': formErrors.has('form-2.waterway_type') }"
                        v-model="form.waterway_type" name="waterway_type">
                  <option value="">Select</option>
                  <option value="1">Natural Freshwater Lake</option>
                  <option value="2">Man-made Freshwater Lake</option>
                  <option value="3">River</option>
                  <option value="4">Reservoir</option>
                  <option value="5">Intracoastal</option>
                  <option value="6">Saltwater</option>
                </select>
                <div v-show="formErrors.has('form-2.waterway_type')" class="invalid-feedback">
                  {{ formErrors.first("form-2.waterway_type") }}
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label class="form-label">Waterfront Site Description<sup class="text-danger">*</sup></label>
                <select class="form-control" data-vv-as="water front" v-validate="'required'"
                        :class="{ 'is-invalid': formErrors.has('form-2.water_front') }"
                        v-model="form.water_front" name="water_front">
                  <option value="">Select</option>
                  <option value="1">Private</option>
                  <option value="2">Semi Private</option>
                  <option value="3">Public</option>
                </select>
                <div v-show="formErrors.has('form-2.water_front')" class="invalid-feedback">
                  {{ formErrors.first("form-2.water_front") }}
                </div>
              </div>
            </div>
            <div class="col-md-12">
              <div class="form-group">
                <label class="form-label">Additional Information</label>
                <textarea type="text" class="form-control" v-model="form.additional_information" name="additional_information"></textarea>
              </div>
            </div>
          </div>
        </form>
      </div>
    </b-modal>
    <b-modal id="editContactInfo" size="lg" ok-title="Update" centered title="Contact Information" @ok="updateContactInfo">
      <div class="form-body">
        <form data-vv-scope="form-3">
          <div class="row">
            <div class="col-md-6">
              <div class="form-group">
                <label class="form-label">Email<sup class="text-danger">*</sup></label>
                <input type="text" class="form-control" data-vv-as="email" v-validate="'required|email'"
                       :class="{ 'is-invalid': formErrors.has('form-3.email') }" v-model="form.email"
                       name="email">
                <div v-show="formErrors.has('form-3.email')" class="invalid-feedback">
                  {{ formErrors.first('form-3.email') }}
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label class="form-label">Phone Number<sup class="text-danger">*</sup></label>
                <vue-tel-input v-model="form.phone_1" ref="phone" v-bind="phoneConfig" @validate="validateTelinput" :validCharactersOnly="true"></vue-tel-input>
                <div class="invalid-feedback" v-show="phone_1_error">{{ phone_1_error }}</div>
              </div>
            </div>
            <div class="col-md-12">
              <label class="form-label">Camp/School Site Address <sup class="text-danger">*</sup></label>
              <div class="row">
                <div class="col-md-6">
                  <div class="form-group">
                    <input type="text" placeholder="Line 1" data-vv-as="Address" v-validate="'required'"
                           :class="{ 'is-invalid': formErrors.has('form-3.address1') }" class="form-control"
                           v-model="form.primaryContact.address1"
                           name="address1">
                    <div v-show="formErrors.has('form-3.address1')" class="invalid-feedback">
                      {{ formErrors.first("form-3.address1") }}
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <input type="text" placeholder="Line 2" data-vv-as="line 2" class="form-control"
                           v-model="form.primaryContact.address2"
                           name="address1">
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-4">
                  <div class="form-group">
                    <input type="text" placeholder="City" v-validate="'required'"
                           :class="{ 'is-invalid': formErrors.has('form-3.city') }" class="form-control"
                           v-model="form.primaryContact.city"
                           name="city">
                    <div v-show="formErrors.has('form-3.city')" class="invalid-feedback">
                      {{ formErrors.first("form-3.city") }}
                    </div>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-group">
                    <select v-validate="'required'" name="state_code"
                            :class="{ 'is-invalid': formErrors.has('form-3.state_code') }" class="form-control"
                            v-model="form.primaryContact.state_code">
                      <option value="">Select State</option>
                      <option v-for="listOfState in listOfStates" :key="listOfState.state_code"
                              :value="listOfState.state_code">{{ listOfState.state }}
                      </option>
                    </select>

                    <div v-show="formErrors.has('form-3.state_code')" class="invalid-feedback">
                      {{ formErrors.first("form-3.state_code") | filterState }}
                    </div>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-group">
                    <input type="text" placeholder="Zip Code"
                           v-validate="{ required: true, regex: /^([0-9]+)$/ }"
                           :class="{ 'is-invalid': formErrors.has('form-3.zip') }" class="form-control"
                           v-model="form.primaryContact.zip"
                           maxlength="5"
                           name="zip">
                    <div v-show="formErrors.has('form-3.zip')" class="invalid-feedback">
                      {{ formErrors.first("form-3.zip") }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-12">
              <label class="form-label">Mailing Address <sup class="text-danger"> * </sup> <input  name="same" style="margin-left: 30px" type="checkbox" v-on:change="isSame()" id='same' v-model="form.same" value=true> Same as Site address</label>
              <div class="row">
                <div class="col-md-6">
                  <div class="form-group">
                    <input type="text" placeholder="Line 1"
                           class="form-control"
                           data-vv-as="Mailing Address" v-validate="'required'"
                           :class="{ 'is-invalid': formErrors.has('form-3.mailingAddress') }"
                           v-model="form.secondaryContact.address1"
                           name="mailingAddress">
                    <div v-show="formErrors.has('form-3.mailingAddress')" class="invalid-feedback">
                      {{ formErrors.first("form-3.mailingAddress") }}
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <input type="text" placeholder="Line 2" class="form-control"
                           v-model="form.secondaryContact.address2"
                           name="address1">
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-4">
                  <div class="form-group">
                    <input type="text" placeholder="City"
                           class="form-control"
                           v-model="form.secondaryContact.city"
                           data-vv-as="City" v-validate="'required'"
                           :class="{ 'is-invalid': formErrors.has('form-3.city2') }"
                           name="city2">
                    <div v-show="formErrors.has('form-3.city2')" class="invalid-feedback">
                      {{ formErrors.first("form-3.city2") }}
                    </div>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-group">
                    <select name="state_code2" class="form-control"
                            data-vv-as="State" v-validate="'required'"
                            :class="{ 'is-invalid': formErrors.has('form-3.state_code2') }"
                            v-model="form.secondaryContact.state_code">
                      <option value="">Select State</option>
                      <option v-for="listOfState in listOfStates" :key="listOfState.state_code"
                              :value="listOfState.state_code">{{ listOfState.state }}
                      </option>
                    </select>
                    <div v-show="formErrors.has('form-3.state_code2')" class="invalid-feedback">
                      {{ formErrors.first("form-3.state_code2") }}
                    </div>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-group">
                    <input type="text" placeholder="Zip Code"
                           class="form-control"
                           data-vv-as="Zip" v-validate="'required'"
                           :class="{ 'is-invalid': formErrors.has('form-3.zip2') }"
                           v-model="form.secondaryContact.zip"
                           maxlength="5"
                           name="zip2">
                    <div v-show="formErrors.has('form-3.zip2')" class="invalid-feedback">
                      {{ formErrors.first("form-3.zip2") }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </b-modal>
  </div>
</template>
<script>
import axios from "axios";
import moment from 'moment';
import { VueTelInput } from 'vue-tel-input';

const validatePhone = (phone, validTelinput, phoneField= '' ) => {
  let phoneText = ( phoneField != '' ? phoneField : "Phone Number");
  if (!phone.length) {
    return { valid: false, error: phoneText+' is required .' };
  }
  if(validTelinput == false && phone.length){
    return { valid: false, error: 'Enter valid phone number.' };
  }
  return { valid: true, error: "" };
}

export default {
  props: ['formData'],
  components: {VueTelInput},
  data(){
    return {
      updateIndex: 0,
      phone_1_error: "",
      validTelinput:false,
      phoneConfig: {
        mode: "international",
        defaultCountry: "US",
        styleClasses: this.phone_1_error !== "" ? "is-invalid" : "",
        inputOptions: {
          autocomplete: "on",
          autofocus: false,
          id: "phone_1",
          maxlength: 15,
          name: "phone_number",
          placeholder: "",
          readonly: false,
          required: false,
          showDialCode: false,
          tabindex: 0,
          type: "tel"
        }
      },
      memberInfo: {
        "company": null,
        "first_name": null,
        "last_name": null,
        "website": null,
        "closest_airport": null,
        "number_of_instructor": null,
        "type": null,
        "month_of_operation": null,
        "discipline_taught": null,
        "equipment": null,
        "skill_level_taught": null,
        "boats": null,
        "waterway_type": null,
        "water_front": null,
        "additional_information": null,
        "email": null,
        "phone_1": null,
        "primaryContact": {
          "address1": null,
          "address2": null,
          "city": null,
          "state_code": null,
          "state": null,
          "zip": null,
        },
        "secondaryContact": {
          "address1": null,
          "address2": null,
          "city": null,
          "state_code": null,
          "state": null,
          "zip": null,
        }
      },
      viewDataError: {},
      form: {
        primaryContact: {},
        secondaryContact: {}
      },
      tabLoading: false,
      profileData: {},
      disciplinesTaught: [
        {text: 'Slalom', value: 'Slalom'},
        {text: 'Trick', value: 'Trick'},
        {text: 'Wakeboard', value: 'Wakeboard'},
        {text: 'Barefoot', value: 'Barefoot'},
        {text: 'Hydrofoil', value: 'Hydrofoil'},
        {text: 'Jump', value: 'Jump'},
        {text: 'Wake Surf', value: 'Wake Surf'},
        {text: 'Show Ski', value: 'Show Ski'},
        {text: 'Adaptive', value: 'Adaptive'},
        {text: 'Kneeboard', value: 'Kneeboard'},
      ],
      equipment: [
        {text: 'Slalom Skis', value: 'Slalom Skis'},
        {text: 'Trick Skis', value: 'Trick Skis'},
        {text: 'Jump Skis', value: 'Jump Skis'},
        {text: 'Beginner Combo Skis', value: 'Beginner Combo Skis'},
        {text: 'Sit Skis (Adaptive)', value: 'Sit Skis (Adaptive)'},
        {text: 'Show Swivel Skis', value: 'Show Swivel Skis'},
        {text: 'Shoe Skis', value: 'Shoe Skis'},
        {text: 'Wake Surf', value: 'Wake Surf'},
        {text: 'Kneeboards', value: 'Kneeboards'},
        {text: 'Wakeboards', value: 'Wakeboards'},
        {text: 'Hydrofoils', value: 'Hydrofoils'},
        {text: 'Students Bring Their Own Equipment', value: 'Students Bring Their Own Equipment'},
      ],
      skillTaught: [
        {text: 'Beginning', value: 'Beginning'},
        {text: 'Intermediate', value: 'Intermediate'},
        {text: 'Advanced', value: 'Advanced'}
      ],
      boats: [
        {text: 'Competition Inboard', value: 'Competition Inboard'},
        {text: 'Competition Outboard', value: 'Competition Outboard'},
        {text: 'Recreational Inboard/Outboard', value: 'Recreational Inboard/Outboard'},
        {text: 'Jet Boat', value: 'Jet Boat'}
      ],
      waterWay: {
        '1': 'Natural Freshwater Lake',
        '2': 'Man-made Freshwater Lake',
        '3': 'River',
        '4': 'Reservoir',
        '5': 'Intracoastal',
        '6': 'Saltwater'
      },
      waterFront: {
        '1': 'Private',
        '2': 'Semi Private',
        '3': 'Public',
      },
      pricing: {}
    };
  },
  methods: {
    getPricing(){
      this.tabLoading = true;
      axios.get(this.basePath+"api/campski/renew/check-pricing", { headers: this.memberHeaders })
      .then(response => {
        this.pricing = response.data.data;
        if (this.pricing.price) {
          this.loadMembersInfo();
        } else {
          this.tabLoading = false;
          this.noPricing = true;
        }
      });
    },
    isSame(){
      if(this.form.same){
        this.form.secondaryContact = Object.assign({},this.form.primaryContact);
        this.form.secondaryContact.primary = 0;
      }
      else{
        this.form.secondaryContact.address1 = "";
        this.form.secondaryContact.address2 = "";
        this.form.secondaryContact.zip = "";
        this.form.secondaryContact.state_code = "";
        this.form.secondaryContact.state = "";
        this.form.secondaryContact.city = "";
        this.form.secondaryContact.country = "USA";
      }
    },
    loadMembersInfo(){
      this.tabLoading = true;
      axios.get(this.basePath+"api/member/my_profile",{ headers:this.memberHeaders })
      .then((response)=>{
        this.profileData = response.data.data;
        this.setViewData(response.data.data);
        this.fillFormData();
        this.tabLoading = false;
      });
    },
    setViewData(resp){
      this.memberInfo.company = this.formData?.company || resp?.campski?.company;
      this.memberInfo.full_name = this.formData?.full_name || resp?.full_name;
      this.memberInfo.first_name = this.formData?.first_name || resp?.first_name;
      this.memberInfo.last_name = this.formData?.last_name || resp?.last_name;
      this.memberInfo.website = this.formData?.website || resp?.campski?.website;
      this.memberInfo.closest_airport = this.formData?.closest_airport || resp?.campski?.closest_airport;
      this.memberInfo.number_of_instructor = this.formData?.number_of_instructor || resp?.campski?.number_of_instructor;
      this.memberInfo.type = this.formData?.type || resp?.campski?.type;
      this.memberInfo.month_of_operation = this.formData?.month_of_operation || resp?.campski?.month_of_operation;
      this.memberInfo.discipline_taught = this.formData?.discipline_taught || resp?.campski?.discipline_taught;
      this.memberInfo.equipment = this.formData?.equipment || resp?.campski?.equipment;
      this.memberInfo.skill_level_taught = this.formData?.skill_level_taught || resp?.campski?.skill_level_taught;
      this.memberInfo.boats = this.formData?.boats || resp?.campski?.boats;
      this.memberInfo.waterway_type = this.formData?.waterway_type || resp?.campski?.waterway_type;
      this.memberInfo.water_front = this.formData?.water_front || resp?.campski?.water_front;
      this.memberInfo.additional_information = this.formData?.additional_information || resp?.campski?.additional_information;
      this.memberInfo.email = this.formData?.email || resp?.email;
      this.memberInfo.phone_1 = this.formData?.phone_1 || resp?.phone_1;
      this.memberInfo.primaryContact = this.formData?.primaryContact || this.primaryContact;
      this.memberInfo.secondaryContact = this.formData?.secondaryContact || this.secondaryContact;

      this.validateViewData();
    },
    validateViewData(){
      this.viewDataError = {};
      if (!this.memberInfo?.email) this.viewDataError.email = "required";
      if (!this.memberInfo?.phone_1) this.viewDataError.phone_1 = "required";

      if (!this.memberInfo?.company) this.viewDataError.company = "required";
      if (!this.memberInfo?.first_name) this.viewDataError.first_name = "required";
      if (!this.memberInfo?.last_name) this.viewDataError.last_name = "required";
      if (!this.memberInfo?.website) this.viewDataError.website = "required";
      if (!this.memberInfo?.month_of_operation) this.viewDataError.month_of_operation = "required";
      if (!this.memberInfo?.discipline_taught) this.viewDataError.discipline_taught = "required";
      if (!this.memberInfo?.equipment) this.viewDataError.equipment = "required";
      if (!this.memberInfo?.skill_level_taught) this.viewDataError.skill_level_taught = "required";
      if (!this.memberInfo?.boats) this.viewDataError.boats = "required";
      if (!this.memberInfo?.waterway_type) this.viewDataError.waterway_type = "required";
      if (!this.memberInfo?.water_front) this.viewDataError.water_front = "required";

      if (!this?.primaryContact?.address1) this.viewDataError.primary_address1 = "required";
      if (!this?.primaryContact?.city) this.viewDataError.primary_city = "required";
      if (!this?.primaryContact?.state_code) this.viewDataError.primary_state_code = "required";
      if (!this?.primaryContact?.zip) this.viewDataError.primary_zip = "required";

      if (!this?.secondaryContact?.address1) this.viewDataError.secondary_address1 = "required";
      if (!this?.secondaryContact?.city) this.viewDataError.secondary_city = "required";
      if (!this?.secondaryContact?.state_code) this.viewDataError.secondary_state_code = "required";
      if (!this?.secondaryContact?.zip) this.viewDataError.secondary_zip = "required";
    },
    fillFormData(){
      this.form.company = this.memberInfo?.company;
      this.form.first_name = this.memberInfo?.first_name;
      this.form.last_name = this.memberInfo?.last_name;
      this.form.website = this.memberInfo?.website;

      this.form.closest_airport = this.memberInfo?.closest_airport;
      this.form.type = this.memberInfo?.type;
      this.form.number_of_instructor = this.memberInfo?.number_of_instructor;
      this.form.month_of_operation = this.memberInfo?.month_of_operation;
      this.form.discipline_taught = this.disciplineTaught;
      this.form.equipment = this.equipmentList;
      this.form.skill_level_taught = this.skillLevelsList;
      this.form.boats = this.boatsList;
      this.form.waterway_type = this.memberInfo?.waterway_type;
      this.form.water_front = this.memberInfo?.water_front;
      this.form.additional_information = this.memberInfo?.additional_information;

      this.form.email = this.memberInfo?.email;
      this.form.phone_1 = this.memberInfo?.phone_1;
      this.form.primaryContact.address1 = this.memberInfo?.primaryContact?.address1;
      this.form.primaryContact.address2 = this.memberInfo?.primaryContact?.address2;
      this.form.primaryContact.city = this.memberInfo?.primaryContact?.city;
      this.form.primaryContact.state_code = this.memberInfo?.primaryContact?.state_code;
      this.form.primaryContact.zip = this.memberInfo?.primaryContact?.zip;
      this.form.secondaryContact.address1 = this.memberInfo?.secondaryContact?.address1;
      this.form.secondaryContact.address2 = this.memberInfo?.secondaryContact?.address2;
      this.form.secondaryContact.city = this.memberInfo?.secondaryContact?.city;
      this.form.secondaryContact.state_code = this.memberInfo?.secondaryContact?.state_code;
      this.form.secondaryContact.zip = this.memberInfo?.secondaryContact?.zip;
    },
    editSchoolInfo(){
      this.fillFormData();
      this.$bvModal.show("editSchoolInfo");
    },
    updateSchoolInfo(bvModalEvt){
      bvModalEvt.preventDefault()
      this.$validator.validateAll('form-1').then((result) => {
        if (result) {
          this.$set(this.memberInfo, "company", this.form.company);
          this.$set(this.memberInfo, "first_name", this.form.first_name);
          this.$set(this.memberInfo, "last_name", this.form.last_name);
          this.$set(this.memberInfo, "website", this.form.website);
          this.updateIndex++;
          this.validateViewData();
          this.$nextTick(() => {
            this.$bvModal.hide('editSchoolInfo')
          });
        }
      });
    },
    editAdditionalInfo(){
      this.fillFormData();
      this.$bvModal.show("editAdditionalInfo");
    },
    updateAdditionalInfo(bvModalEvt){
      bvModalEvt.preventDefault()
      this.$validator.validateAll('form-2').then((result) => {
        if (result) {
          this.$set(this.memberInfo, "closest_airport", this.form.closest_airport);
          this.$set(this.memberInfo, "type", this.form.type);
          this.$set(this.memberInfo, "number_of_instructor", this.form.number_of_instructor);
          this.$set(this.memberInfo, "month_of_operation", this.form.month_of_operation);
          this.$set(this.memberInfo, "discipline_taught", this.form.discipline_taught.join(","));
          this.$set(this.memberInfo, "equipment", this.form.equipment.join(","));
          this.$set(this.memberInfo, "skill_level_taught", this.form.skill_level_taught.join(","));
          this.$set(this.memberInfo, "boats", this.form.boats.join(","));
          this.$set(this.memberInfo, "waterway_type", this.form.waterway_type);
          this.$set(this.memberInfo, "water_front", this.form.water_front);
          this.$set(this.memberInfo, "additional_information", this.form.additional_information);
          this.updateIndex++;
          this.validateViewData();
          this.$nextTick(() => {
            this.$bvModal.hide('editAdditionalInfo')
          });
        }
      });
    },
    editContactInfo(){
      this.fillFormData();
      this.$bvModal.show("editContactInfo");
    },
    updateContactInfo(bvModalEvt){
      bvModalEvt.preventDefault();
      this.phone_1_error = "";
      if(this.form.phone_1==="") this.phone_1_error = "Phone Number is required";
      else {
        const validPhone1 = validatePhone(this.form.phone_1, this.validTelinput);
        this.phone_1_error = validPhone1.error;
      }
      this.$validator.validateAll('form-3').then((result) => {
        if (result) {
          this.$set(this.memberInfo, 'email', this.form?.email);
          this.$set(this.memberInfo, 'phone_1', this.form?.phone_1);
          this.$set(this.memberInfo.primaryContact, 'address1', this.form?.primaryContact?.address1);
          this.$set(this.memberInfo.primaryContact, 'address2', this.form?.primaryContact?.address2);
          this.$set(this.memberInfo.primaryContact, 'city', this.form?.primaryContact?.city);
          this.$set(this.memberInfo.primaryContact, 'state_code', this.form?.primaryContact?.state_code);
          this.$set(this.memberInfo.primaryContact, 'zip', this.form?.primaryContact?.zip);
          this.$set(this.memberInfo.secondaryContact, 'address1', this.form?.secondaryContact?.address1);
          this.$set(this.memberInfo.secondaryContact, 'address2', this.form?.secondaryContact?.address2);
          this.$set(this.memberInfo.secondaryContact, 'city', this.form?.secondaryContact?.city);
          this.$set(this.memberInfo.secondaryContact, 'state_code', this.form?.secondaryContact?.state_code);
          this.$set(this.memberInfo.secondaryContact, 'zip', this.form?.secondaryContact?.zip);
          this.updateIndex++;
          this.validateViewData();
          this.$nextTick(() => {
            this.$bvModal.hide('editContactInfo')
          });
        } else {
          console.log(this.$validator.errors);
        }
      });
    },
    nextStep(){
      if (Object.keys(this.viewDataError).length === 0) {
        this.$emit('recordVerify', {memberInfo: this.memberInfo, pricing: this.pricing});
      }
    }
  },
  mounted() {
    this.getPricing();
  },
  computed: {
    membershipName: function(){
      return this.profileData?.membership?.name;
    },
    membershipStartDate: function(){
      return moment(this.profileData?.start_date).format("MM/DD/YYYY");
    },
    membershipEndDate: function(){
      return moment(this.profileData?.valid_thru).format("MM/DD/YYYY");
    },
    disciplineTaught: function(){
      return this.memberInfo?.discipline_taught?.split(",");
    },
    equipmentList: function(){
      return this.memberInfo?.equipment?.split(",");
    },
    skillLevelsList: function(){
      return this.memberInfo?.skill_level_taught?.split(",");
    },
    boatsList: function(){
      return this.memberInfo?.boats?.split(",");
    },
    waterWayList: function(){
      let wf = this.memberInfo?.waterway_type;
      return wf ? this.waterWay[wf] : null;
    },
    waterFrontList: function(){
      let wf = this.memberInfo?.water_front;
      return wf ? this.waterFront[wf] : null;
    },
    primaryContact: function(){
      if (this.memberInfo?.primaryContact?.address1 == null) {
        let data = this.profileData?.campski_contact_details?.filter(item => item.primary === 1);
        if(data?.[0] !== undefined) {
          return {
            address1: data?.[0].address1,
            address2: data?.[0].address2,
            city: data?.[0].city,
            state_code: data?.[0].state_code,
            zip: data?.[0].zip,
          };
        } else {
          return {};
        }
      } else {
        return this.memberInfo?.primaryContact;
      }
    },
    secondaryContact: function(){
      if (this.memberInfo?.secondaryContact?.address1 == null) {
        let data = this.profileData?.campski_contact_details?.filter(item => item.primary !== 1);
        if(data?.[0] !== undefined) {
          return {
            address1: data?.[0].address1,
            address2: data?.[0].address2,
            city: data?.[0].city,
            state_code: data?.[0].state_code,
            zip: data?.[0].zip,
          };
        } else {
          return {};
        }
      } else {
        return this.memberInfo?.secondaryContact;
      }
    },
    viewDataErrorExists: function(){
      return Object.keys(this.viewDataError).length !== 0;
    }
  }
}
</script>